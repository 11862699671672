$primary-color: #173b50;
$secondly-color: #b9b16d;
$black-color: #333333;
$white-color: #ffffff;
$gold-color: linear-gradient(135deg, #ded585 0%, #b9b16d 100%);

@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+JP&display=swap");
ul {
    padding: 0;
}

header {
    display: none;
}

.secondly-color {
    color: $secondly-color !important;
}

.primary-color {
    color: $primary-color !important;
}

.text-primary-color {
    color: #173b50;
}

.text-gold-color {
    background: -webkit-linear-gradient(94deg, #ded585 0%, #b9b16d 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.mr-20 {
    margin-right: 20px;
}

.mr-30 {
    margin-right: 30px;
}

.px-m-20 {
    padding-right: 20px;
    padding-left: 20px;
}

.banner-image {
    // background: linear-gradient(90deg, #e2e3e2 0%, #e2e3e2 50% , #e2e4e5 51%, #e2e4e5 100%);
    background: url("/img/user/banner.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top center;
}

.banner {
    margin: 0 auto;
    max-width: 1366px;
    // width: 100%;
    // background: url("/img/user/banner.jpg");
    // background-repeat: no-repeat;
    // background-size: cover;
    // background-position: center;
    // padding-top: 26px;
    // height: 700px;
}

.banner-new {
    position: relative;
}

.header-banner {
    position: absolute;
    top: 0;
}

.image-kv-sp {
    display: none;
}

.button-container {
    position: absolute;
    bottom: 15.5%;
    right: 11.5%;
    width: 30%;
    background: #173b50 0% 0% no-repeat padding-box;
    padding: 5px;

    &:hover {
        background: #162839;
    }
}

.button-container .banner-button {
    padding: 4.5%;
}

.banner-button {
    width: 100%;
    padding: 3%;
    border: 1px solid #ffffff4d;
    color: $white-color;
    font-weight: bold;

    &:hover {
        color: $white-color;
    }
}

.font-custom {
    font-size: calc(15px + 56*((100vw - 80vw)/1200));
    line-height: calc(15px + 56*((100vw - 80vw)/1200));
}

.header-banner .navbar {
    padding: 26px 33px;
}

.banner-logo {
    max-width: 160px;
    max-height: 40px;
}

.navbar-header {
    background-color: transparent !important;
}

.navbar-header a {
    font-size: 16px;
    line-height: 24px;
}

.navbar-header .text-nav {
    color: $primary-color;
}

.moveheader {
    display: block;
    position: fixed;
    width: 100%;
    z-index: 1;
    top: 0;
    animation: moveheader 0.8s ease;
}

.moveheader a {
    color: $white-color;
}

.moveheader img {
    visibility: inherit !important;
}

@keyframes moveheader {
    from {
        top: -70px;
    }
    to {
        top: 0px;
    }
}

.navbar-header .btn-login {
    display: table-cell;
    vertical-align: middle;
    // max-width: 148px;
    padding: 12px 40px;
    background-color: $primary-color;
    color: $white-color;
    font-size: 16px;
    letter-spacing: 1.2px;
}

.navbar-header .btn-register {
    display: table-cell;
    vertical-align: middle;
    // width: 148px;
    background-image: $gold-color;
    color: $white-color;
    font-size: 16px;
    padding: 12px 42px;
}

.btn-nav-link {
    padding: 12px 10px;
    display: table-cell;
    vertical-align: middle;
}

a:hover {
    text-decoration: none;
}

.banner-wrapper {
    margin-top: 80px;
}

.banner-item {
    height: 100%;
}

.banner-item p {
    font-family: "Noto Serif", serif;
    color: $primary-color;
    margin: 0;
    padding: 0;
    font-size: 14px;
    letter-spacing: 0px;
    line-height: 19px;
}

.banner-item h3 {
    font-size: 24px;
    line-height: 24px;
}

.banner-item h4 {
    font-family: "Noto Serif", serif;
    color: $white-color;
    font-size: 18px;
    line-height: 24px;
}

.banner-item-text {
    font-size: 24px !important;
}

.banner-item-text span {
    font-size: 18px;
}

.banner-title {
    width: 84px;
}

.banner-title2 {
    width: 98px;
}

.banner-text {
    font-family: "Noto Serif", serif;
    font-size: 48px;
    line-height: 72px;
    background: $primary-color;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.banner-container {
    width: 400px;
    height: 80px;
    padding: 5px;
    margin: 0 auto;
    background: $primary-color 0% 0% no-repeat padding-box;
    letter-spacing: 2.1px;
    box-shadow: 2px 2px 4px #00000033;
    margin-bottom: 70px;
}

// .banner-button {
//     font-family: "Noto Serif", serif;
//     width: 390px;
//     padding: 15px 0 15px 0;
//     box-shadow: 2px 2px 4px #00000033;
//     border: 1px solid #ffffff4d;
//     color: $white-color;
//     font-size: 28px;
//     line-height: 42px;
// }

.border-2 {
    border: 2px solid #ffffff4d;
}

.button-mobile {
    display: none;
}

/* Slider */
.slider {
    padding: 20px auto;
    height: 100%;
    width: 100%;
    background: url("/img/user/Mask_Group 650.png");
    background-repeat: no-repeat;
    background-size: cover;
}

.slider h2 {
    background: $white-color;
    -webkit-background-clip: text;
    font-weight: bold;
    font-size: 24px;
    line-height: 36px;
}

.slider h1 {
    font-family: "Noto Serif", serif;
    color: $white-color;
    font-weight: bold;
    font-size: 36px;
    line-height: 54px;
}

.slider-wrapp-text {
    margin: 0 auto;
    max-width: 800px;
    color: $white-color;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0px;
}

.slider-wrapper {
    height: 272px;
}

.slider-box {
    width: 460px;
    background-color: $white-color;
    padding: 24px 20px !important;
}

.slider-box img {
    width: 60px;
    height: 60px;
}

.slider-box-title {
    text-align: left;
    font-weight: bold;
    letter-spacing: 0px;
    color: #3b4043;
}

.slider-box-title div {
    font-size: 18px;
    line-height: 24px;
}

.slider-box-title h3 {
    font-size: 18px;
    line-height: 24px;
}

.slider-box-title span {
    font-size: 24px;
    line-height: 24px;
    color: red;
    font-weight: bold;
}

.slider-box-icon {
    color: $black-color;
    font-size: 26px;
    opacity: 0.4;
}

.slider-wrapper-icon {
    width: 60px;
    height: 60px;
    background-color: $primary-color;
    color: $white-color;
    display: flex;
    align-items: center;
    justify-content: center;
}

.slider-wrapper-icon i {
    font-size: 26px;
}

.slider-pc-link {
    margin: 0 15px;
}

.width-30 {
    width: 30%;
}

.width-70 {
    width: 65%;
}

.m-top-60 {
    margin-top: 60px;
}

.m-bottom-20 {
    margin-bottom: 20px;
}

.slider-box-text {
    color: $white-color;
    font-size: 14px;
    line-height: 27px;
}

.width-70 .slider-box-text {
    color: $black-color;
}

.slider-box-item {
    margin-bottom: 2px;
}

//INTRODUCE

.introduce-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    background-color: $white-color;
    margin-bottom: 60px;
}

.mb-introduce-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    background-color: $white-color;
    margin-bottom: 30px;
    padding-bottom: 40px;
}

.introduce-item {
    width: 940px;
    height: 100%;
    padding-bottom: 30px;
    background-color: #edf3f7;
}

.mb-introduce-item {
    margin: 20px;
    height: 100%;
    background-color: #edf3f7;
    margin-bottom: 20px;
}

.re-positon {
    position: relative;
    right: -10px;
    top: 24px;
}

.info p {
    margin-bottom: 0px;
    color: #3b4043;
    position: relative;
    width: calc(100% - 210px);
    float: right;
    top: 25px;
}

.introduce-img-contain {
    position: relative;
}

.introduce-img-left {
    display: flex;
    position: absolute;
    align-items: center;
    top: -20px;
    left: -20px;
}

.mb-introduce-img-left {
    width: 80px;
    height: 80px;
    object-fit: cover;
}

.mb-introduce-img-left img {
    width: 100%;
    height: 100%;
}

.mb-intro-title {
    padding-left: 15px;
    font-size: 16px;
    line-height: 24px;
    color: #3B4043;
}

.mb-salary {
    font-weight: bold;
    font-size: 20px;
    line-height: 21px;
}

.mb-info-right {
    font-size: 14px;
    line-height: 21px;
}

.introduce-img-right {
    display: flex;
    position: absolute;
    align-items: center;
    top: -20px;
    right: -20px;
}

.display-col {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.introduce-title {
    position: relative;
    background-color: $primary-color;
    width: calc(100% - 270px);
    height: 112px;
    right: -10px;
    margin-bottom: 19px;
    top: 25px;
}

.mb-introduce-img-contain {
    margin-bottom: 15px;
}

.mb-introduce-content {
    padding: 15px;
}

.mb-introduce-content p {
    font-size: 14px;
    line-height: 24.5px;
}

.mb-introduce-title {
    background-color: $primary-color;
    width: 100%;
    padding: 10px;
    margin-bottom: 19px;
    top: 25px;
}

.mb-changing-job-title {
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    line-height: 21px;
}

.mb-changing-job-hr {
    margin: 0;
}

.mb-changing-job {
    height: 100%;
}

.mb-changing-job-tranfer {
    position: relative;
    // display: flex;
    margin: 0 0 15px 0;
    width: 100%;
    height: 70px;
    justify-content: center;
    align-items: center;
}

.mb-arrow {
    position: relative;
    width: 20px;
    background-color: #173b50;
    height: 30px;
    margin-right: 20px;
}

.mb-salary-increase {
    text-align: right;
}

.mb-arrow::after {
    content: "";
    position: absolute;
    bottom: -30px;
    width: 0;
    height: 0;
    border-top: 20px solid transparent;
    border-bottom: 20px solid transparent;
    border-left: 20px solid $primary-color;
    transform: rotateZ(90deg);
}

.mb-salary-increase {
    width: 98px;
    color: #cc0000;
}

.mb-salary-text {
    font-size: 14px;
    font-weight: bold;
}

.mb-introduct-description {
    padding: 0 37px;
    font-size: 24px !important;
    line-height: 36px !important;
}

.mb-title-about {
    font-weight: bold;
    font-size: 18px;
    line-height: 27px;
}

.introduct-title {
    font-weight: bold;
    font-size: 24px;
    line-height: 36px;
}

.introduct-description {
    font-size: 36px;
    line-height: 54px;
}

.title-about {
    font-size: 28px;
    line-height: 42px;
}

.introduce-title-right {
    float: right;
    right: -10px;
}

.introduce-title-left {
    float: left;
    left: -10px;
}

.info-right {
    right: -100px;
    font-size: 16px;
    line-height: 28px;
}

.info-left {
    float: left !important;
    right: 0;
}

.introduce-title-right::after {
    content: "";
    position: absolute;
    bottom: -10px;
    right: 0;
    border-top: 10px solid #072231;
    border-right: 10px solid transparent;
}

.introduce-title-left::after {
    content: "";
    position: absolute;
    bottom: -10px;
    border-top: 10px solid #072231;
    border-left: 10px solid transparent;
}

.introduce-title h2 {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 8%;
    font-size: 28px;
    line-height: 42px;
    font-weight: bold;
}

.introduce-content {
    color: #3b4043;
    margin-top: 210px;
    padding: 0px 30px 0px 30px;
}

.introduce-content h5 {
    font-family: 'Noto Sans JP', sans-serif;
    font-size: 18px;
    line-height: 27px;
}

.introduce-content p {
    font-size: 16px;
    line-height: 28px;
}

.changing-job-title {
    font-size: 16px;
    line-height: 27px;
    font-weight: bold;
}

.changing-job-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 0px 30px 0px 30px;
}

.changing-job {
    background-color: $white-color;
    width: 360px;
    height: 147px;
    text-align: center;
}

.changing-job-title {
    margin-top: 8px;
}

.changing-job-hr {
    margin-top: 0px;
    width: 100%;
    height: 2px;
    color: #edf3f7;
}

.changing-job-tranfer {
    padding: 0 30px;
}

.salary-increase {
    width: 98px;
    color: #cc0000;
}

.salary {
    font-weight: bold;
    font-size: 24px;
    line-height: 24px;
}

.salary-text {
    font-size: 16px;
    line-height: 24px;
}

.changing-job-tranfer {
    margin-bottom: 50px;
}

.arrow {
    position: relative;
    width: 30px;
    background-color: $primary-color;
    height: 20px;
    margin-right: 20px;
}

.arrow::after {
    content: "";
    position: absolute;
    bottom: -10px;
    right: -20px;
    width: 0;
    height: 0;
    border-top: 20px solid transparent;
    border-bottom: 20px solid transparent;
    border-left: 20px solid $primary-color;
}

#slidemobile {
    display: none !important;
}

.carousel-control-prev {
    width: 5%;
}

.carousel-control-prev-icon {
    width: 50px;
    height: 50px;
    background-color: $primary-color;
    color: $white-color;
}

.carousel-control-next-icon {
    width: 50px;
    height: 50px;
    background-color: $primary-color;
}

.carousel-control-next {
    width: 5%;
}

.button-default {
    background-image: url("/img/user/背景.png");
    background-attachment: fixed;
    background-position: center top;
}

.button-default .banner-container {
    background: transparent linear-gradient(101deg, #DED585 0%, #B9B16D 100%) 0% 0% no-repeat padding-box;
    margin-bottom: 7px;

    &:hover {
        background: #94925B;
    }
}

.button-link {
    line-height: 15px;
}

.button-link a {
    text-decoration: underline;
    color: #ffffff;
    font-size: 14px;
}

.button-link div {
    display: inline-block;
    padding: 0 5px;
    color: $white-color;
}

//Project
.project {
    background: url("/img/user/project.png");
    background-color: #151515;
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: center top;
    padding-top: 60px;
    padding-bottom: 80px;
}

.project h3 {
    font-size: 24px;
    line-height: 36px;
}

.project-wrapper {
    background-color: $white-color;
    padding-top: 60px;
    padding-bottom: 80px;
    margin-bottom: 60px;
}

.project h1 {
    font-family: "Noto Serif", serif;
    font-size: 36px;
    line-height: 63px;
    margin-bottom: 40px;
}

.project p {
    font-size: 16px;
    line-height: 28px;
}

.project-image {
    margin-top: 40px;
    padding: 0 131px 0 125px;
}

.project-image-item {
    padding: 20px 16px;
    max-width: 45%;
    border: 1px solid #dddddd;
}

.project-image-item:first-child {
    margin-right: 40px;
    padding: 18px 30px 12px 30px;
}

.project-wrapper-item {
    padding: 0 90px;
}

.project-wrapper-item hr {
    opacity: 0.7;
    border-top: 2px solid #173b50;
}

.project-wrapper h3 {
    margin-bottom: 61px;
}

.title-mobile {
    padding: 0 33px;
}

.title-mobile-2 {
    padding: 0 13px;
}

.project-title-right {
    font-size: 28px;
    line-height: 42px;
}

.project-title-left {
    font-size: 80px;
    line-height: 80px;
}

.project-content {
    font-size: 16px;
    line-height: 28px;
    color: #3b4043;
}

.project-content {
    min-height: 80px;
}

.project-icon i {
    font-size: 90px;
}

.project-item-mobile {
    display: none;
}

.steps {
    background-image: url("/img/user/slider2.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    color: $primary-color;
    padding: 60px 0;
}

.steps-content {
    max-width: 800px;
    margin: 0 auto;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0px;
    color: #3b4043;
}

.steps h1 {
    font-weight: bold;
    font-size: 36px;
    line-height: 54px;
}

.steps-title-mobile h1 {
    font-size: 48px;
    line-height: 64px;
}

.steps-wrapper {
    max-width: 900px;
    margin: 0 auto;
}

.steps-wrapper-item {
    max-width: 250px;
    background-color: $white-color;
    margin-bottom: 20px;
}

.steps-wrapper-header {
    height: 5px;
    background-image: $gold-color;
}

.steps-wrapper-item h5 {
    font-weight: bold;
    font-size: 18px;
    line-height: 27px;
}

.steps-wrapper-item h4 {
    font-weight: bold;
    font-size: 18px;
    line-height: 27px;
    letter-spacing: 0px;
    color: #3b4043;
    font-family: 'Noto Sans JP', sans-serif;
}

.steps-wrapper-item h1 {
    line-height: 1;
}

.steps-wrapper-item p {
    padding-bottom: 20px;
    font-size: 12px;
    letter-spacing: 0px;
    color: #3b4043;
    width: 210px;
    margin: 0 auto;
    text-align: left;
}

.steps-wrapper-icon {
    background-image: url("/img/user/Elip.png");
    background-position: center;
    background-repeat: no-repeat;
    position: relative;
    height: 120px;
}

.steps-wrapper-icon img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.banner-contain {
    padding-right: 43px;
    display: grid;
    justify-content: end;
}

.banner-contain h3 {
    font-family: "Noto Serif", serif;
    font-size: 28px;
    line-height: 42px;
}

.font-40 {
    font-size: 40px;
    line-height: 42px;
}

.section-question {
    background-image: url("/img/user/bg_question.png") !important;
    background-color: #000000;
}

.title-header h1 {
    background: -webkit-linear-gradient(94deg, #ded585 0%, #b9b16d 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: "Noto Serif", serif;
}

.introduct-mobile {
    display: none;
}

.mb-introduce-container {
    padding-top: 30px;
}

@media (max-width: 1200px) {
    .introduce-item {
        width: 82%;
    }

    .font-custom {
        font-size: calc(15px + 56*((100vw - 91vw)/1200));
        line-height: calc(15px + 56*((100vw - 91vw)/1200));
    }

    .navbar-header a {
        font-size: 14px;
        line-height: 22px;
    }

    .nav-item {
        a {
            font-size: 14px;
            line-height: 22px;
        }
    }

    .btn-nav-link {
        padding: 11px 10px;
    }

    .navbar-header .btn-login {
        font-size: 14px;
        padding: 11px 38px;
    }

    .navbar-header .btn-register {
        font-size: 14px;
        padding: 11px 38px;
    }
}

@media (max-width: 1024px) {
    .font-custom {
        font-size: calc(15px + 56*((100vw - 96vw)/1200));
        line-height: calc(15px + 56*((100vw - 96vw)/1200));
    }
}

@media (max-width: 1200px) and (min-width: 800px) {
    .introduce-title h2 {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 8%;
        right: 2%;
        font-size: 18px;
        line-height: 32px;
        font-weight: bold;
    }
}

@media (max-width: 990px) {
    .font-custom {
        font-size: calc(15px + 56*((100vw - 98vw)/1200));
        line-height: calc(15px + 56*((100vw - 98vw)/1200));
    }

    .project-wrapper-item {
        padding: 0 35px;
    }

    .mr-20 {
        margin-right: 10px;
    }

    .project-title-right {
        width: 75%;
        font-size: 26px;
        line-height: 42px;
    }

    .project-title-left {
        font-size: 70px;
        line-height: 70px;
        width: 20%;
    }

    .navbar-header a {
        font-size: 12px;
        line-height: 20px;
    }

    .btn-nav-link {
        padding: 10px 10px;
    }
}

@media (max-width: 799px) {
    .btn-nav-link {
        display: none;
    }

    footer {
        margin-bottom: 104px;
    }

    .button-mobile {
        display: block;
        position: fixed;
        bottom: 0;
        z-index: 2;
        width: 100%;
    }

    .btn img {
        max-height: 30px;
        max-width: 130px;
        visibility: inherit;
    }

    .banner {
        font-family: "Noto Sans JP", sans-serif;
        width: 100%;
        background: url("/img/user/doctor01_sp.jpg");
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        padding-bottom: 380px;
        // padding-top: 15px;
        max-height: 562px;
    }

    .image-kv {
        display: none;
    }

    .image-kv-sp {
        display: block;
    }

    .button-container {
        display: none;
    }

    // .header-banner .navbar {
    //     padding: 0;
    // }

    .banner-wrapper {
        margin-top: 20px;
        display: flex;
        justify-content: center;
        max-width: 350px;
        width: 100%;
        max-height: 60px;
        height: 100%;
    }

    .header-banner {
        max-height: 40px;
    }

    .navbar-header .btn-login {
        font-size: 12px;
        line-height: 30px;
        padding: 0px 20px;
    }

    .button-right {
        display: none;
    }

    .banner-contain {
        padding: 0;
        display: grid;
        justify-content: center;
    }

    .banner-item {
        margin-right: 12px !important;
    }

    .banner-item img {
        max-width: 25.03px;
        max-height: 100%;
    }

    .banner-item:last-child {
        margin-right: 0px !important;
    }

    .banner-item h3 {
        font-size: 20px;
    }

    .banner-item h4 {
        font-size: 10px;
        line-height: 14px;
    }

    .banner-item p {
        max-width: 56px;
        font-size: 8px;
        line-height: 10px;
    }

    .banner-item-text {
        font-size: 13px !important;
    }

    .banner-contain h2 {
        margin-top: 0px !important;
        font-size: 27px;
        line-height: 41px;
    }

    .banner-contain h3 {
        font-size: 16px;
        line-height: 24px;
        margin-top: 0 !important;
    }

    .font-40 {
        font-size: 23px;
        line-height: 24px;
    }

    .banner-item span {
        font-size: 10px;
    }

    .introduct-title {
        font-size: 18px;
        line-height: 27px;
    }

    .banner-title span {
        font-size: 8px;
    }

    .slider h2 {
        font-weight: bold;
        font-size: 18px;
        line-height: 27px;
    }

    .slider {
        height: auto;
    }

    .slider h1 {
        font-weight: bold;
        font-size: 28px;
        line-height: 42px;
        margin-bottom: 20px;
    }

    .slider-wrapp-text {
        margin-top: 0 !important;
        max-width: 335px;
        font-size: 14px;
        line-height: 25px;
        text-align: left !important;
    }

    .slider-box-text {
        font-size: 12px;
    }

    .slide-button-left {
        top: 45%;
        left: -4%;
        z-index: 1;
    }

    .slide-button-right {
        top: 45%;
        right: -4%;
        z-index: 1;
    }

    .banner-text {
        font-size: 41px;
    }

    .banner-container {
        width: 100%;
        height: 48px;
        visibility: hidden;
    }

    .button-pc {
        display: none;
    }

    .button-mobile {
        display: block;
        bottom: 0;
    }

    .button-default {
        background: #000000;
    }

    .banner-container-moblie {
        visibility: visible;
    }

    .banner-button {
        width: 100%;
        box-shadow: none;
        border: none;
        font-size: 16px;
        line-height: 30px;
        padding: 8px 0 7px 0;
    }

    .button-link a {
        font-size: 10px;
    }

    .slider-mobile {
        margin-top: 30px !important;
    }

    .carousel-item-mobile {
        display: none;
    }

    #slidemobile {
        display: flex !important;
    }

    #slidepc {
        display: none !important;
    }

    /* Slide moblie */
    .slider-box {
        width: 100%;
        margin: 0 auto !important;
    }

    .slider-box img {
        width: 40px;
        height: 40px;
    }

    .slider-box-title {
        font-weight: bold;
        font-size: 16px;
        line-height: 24px;
    }

    .slider-wrapper-icon {
        width: 30px;
        height: 30px;
    }

    .slider-wrapper-icon i {
        font-size: 20px;
    }

    .project {
        padding-top: 35px;
        padding-bottom: 40px;
    }

    .project-wrapper {
        padding: 30px 20px 40px 20px;
        margin-bottom: 30px;
    }

    .project h3 {
        font-size: 18px;
        line-height: 27px;
    }

    .project h1 {
        margin-bottom: 20px;
        font-size: 28px;
        line-height: 42px;
    }

    .project p {
        text-align: left !important;
        font-size: 14px;
        line-height: 25px;
    }

    .project-image {
        margin: 0;
        display: block;
        margin-top: 20px;
        padding: 0;
    }

    .project-image-item {
        max-width: 100%;
        padding: 10px 23px;
    }

    .project-image-item:first-child {
        margin: 0 0 20px;
    }

    .project-wrapper .title-mobile {
        font-size: 24px;
        line-height: 36px;
    }

    .project-wrapper .title-mobile-2 {
        font-size: 24px;
        line-height: 36px;
    }

    .project-wrapper h3 {
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 35px;
    }

    .project-wrapper p {
        padding: 0;
    }

    .project-wrapper-item {
        padding: 0;
    }

    .project-title-left {
        font-size: 40px;
        line-height: 54px;
        max-width: 60px;
        margin: 0;
    }

    .project-title-right {
        font-size: 18px;
        line-height: 27px;
    }

    .project-item-pc {
        display: none;
    }

    .project-item-mobile {
        display: block;
    }

    .project-content img {
        margin-right: 10px;
        width: 80px;
        height: 80px;
        float: left;
        object-fit: cover;
    }

    .project-icon i {
        font-size: 50px;
    }

    .introduct-pc {
        display: none;
    }

    .mb-introduce-container {
        display: block;
    }

    .steps {
        padding: 40px 0 !important;
    }

    .steps-wrapper {
        display: inline-block !important;
        width: 100%;
        margin-top: 20px !important;
    }

    .steps-wrapper-item {
        max-width: 700px;
        background-color: $white-color;
        margin-bottom: 20px !important;
    }

    .step-wrapper-mobile {
        display: flex;
        justify-content: center;
    }

    .steps-wrapper-icon {
        width: 80px;
        height: 80px;
        border-radius: 50%;
        margin: 0 20px !important;
    }

    .steps-wrapper-icon img {
        width: 33px;
    }

    .steps-title-mobile {
        margin-top: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: "Noto Serif", serif;
    }

    .steps-wrapper-right {
        max-width: 195px;
    }

    .steps h1 {
        font-weight: bold;
        font-size: 28px;
        line-height: 42px;
    }

    .steps-title-mobile h1 {
        font-size: 24px;
        line-height: 36px;
    }

    .steps p {
        font-size: 14px;
        line-height: 25px;
    }

    .steps-wrapper-item h4 {
        text-align: left;
    }

    .steps-wrapper-item h5 {
        padding-top: 0 !important;
        font-weight: bold;
        font-size: 24px;
        line-height: 36px;
        margin-right: 5px;
    }

    .steps-wrapper-item p {
        padding-bottom: 30px !important;
        padding-right: 20px;
        font-size: 12px;
        line-height: 21px;
        max-width: 295px !important;
        width: 100%;
    }

    .title-header h1 {
        font-size: 28px;
        line-height: 42px;
    }

    .mb-changing-job {
        background-color: #ffffff;
    }

    .mb-intro-title h5 {
        font-family: 'Noto Sans JP', sans-serif;
        font-size: 16px;
        line-height: 24px;
    }

    .mb-intro-title h5 {
        font-size: 16px;
        line-height: 24px;
    }
}

@media (max-width: 550px) {
    .banner {
        height: 600px;
    }

    .header-banner .navbar {
        padding: 12px 10px;
    }
}

@media (max-width: 350px) {
    .banner {
        height: 550px;
    }

    .navbar.navbar-header {
        max-height: 30px;
        padding: 8px 8px;
    }

    .btn {
        padding: 0;
    }

    .banner-item img {
        max-height: 80%;
    }

    .banner-item p {
        max-width: 43px;
        font-size: 6px;
        line-height: 8px;
    }

    .banner-item h4 {
        font-size: 8px;
        line-height: 9px;
    }

    .banner-wrapper {
        max-height: 55px;
    }

    .banner-contain h3 {
        font-size: 13px;
    }

    .font-40 {
        font-size: 18px;
    }

    .banner-contain h2 {
        font-size: 22px;
        line-height: 26px;
    }

    .banner-item-text {
        font-size: 10px !important;
    }

    // .banner-button {
    //     padding: 13px 0 13px 0;
    // }
}

@media (max-width: 320px) {
    footer {
        margin-bottom: 122px;
    }
}

@media (max-width: 316px) {
    .steps-wrapper-icon {
        width: 50px;
        height: 50px;
    }

    .steps-wrapper-right {
        max-width: 170px;
    }

    .steps-wrapper-icon img {
        width: 23px;
    }
}
